import { storeToRefs } from 'pinia'
import { useLobbyGameApi } from '~/api/lobby-game'
import { useAppStore } from '~/store/app'
import { MODAL } from '~/constants'
import useModal from '~/composables/useModal'
import useNavigationGame from '~/composables/game/useNavigationGame'
import { IBannerItem } from '~/types/home'

export default () => {
  const { $axios, $pinia } = useNuxtApp()
  const { HOME_BANNER } = useLobbyGameApi()
  const store = useAppStore($pinia)
  const { currentUser } = storeToRefs(store)
  const { openModalWithNavigate } = useModal()
  const { openGameByUrl } = useNavigationGame()
  const router = useRouter()
  const bannerData = ref<IBannerItem[]>([])

  const handleClickBanner = (item: IBannerItem) => {
    if (item?.api_url.includes('partnerprovider') && item?.api_url.includes('partnergameid')) {
      openGameByUrl(item?.api_url)
    } else if (item.api_url.includes('account')) {
      if (!currentUser || !currentUser.value) {
        return openModalWithNavigate(MODAL.AUTHENTICATION, MODAL.LOGIN)
      } else {
        router.push(item.api_url)
      }
    } else {
      router.push(item.api_url)
    }
  }

  const fetchBanner = async () => {
    try {
      const { data: response } = await $axios.get(HOME_BANNER)
      if (response && response.status === 'OK') {
        if (response.data?.length > 0) {
          bannerData.value = response.data.filter((item: Record<string, any>) => item.img !== null)
        }
      }
    } catch (error) {
    }
  }

  return {
    bannerData,
    fetchBanner,
    handleClickBanner
  }
}
